import GATSBY_COMPILED_MDX from "/opt/build/repo/posts/vermont-syruptastic/index.mdx";
import React from "react";
import BlogLayout from "../components/bloglayout";
import {Link, graphql} from "gatsby";
import SEO from "../components/seo";
import {Container, Row, Col, Badge} from "reactstrap";
import {slugify} from "../util/utilityFunctions";
import {GatsbyImage} from "gatsby-plugin-image";
import BlogAvatar from "../components/BlogAvatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faReddit} from "@fortawesome/free-brands-svg-icons";
import Sidebar from "../components/sidebar";
import styled from "styled-components";
import {colors, fonts} from "../../theme.js";
class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx;
    const mdxContent = this.props.children;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const siteUrl = this.props.data.site.siteMetadata.siteUrl;
    const getColor = tag => {
      if (tag === "Spanish") {
        return "badge-spanish text-uppercase";
      } else if (tag === "French") {
        return "badge-french text-uppercase";
      } else if (tag === "Chinese") {
        return "badge-chinese text-uppercase";
      } else if (tag === "German") {
        return "badge-german text-uppercase";
      } else if (tag === "Politics") {
        return "badge-politics text-uppercase";
      } else {
        return "badge-generic text-uppercase";
      }
    };
    const FeaturedImage = post.frontmatter.featuredImage.childImageSharp.gatsbyImageData;
    const OgUrl = `${siteUrl}${post.fields.slug}`;
    const OgImage = `${siteUrl}${post.frontmatter.featuredImage.childImageSharp.gatsbyImageData.src}`;
    const SchemaOrg = post.frontmatter.schema;
    const IsReview = SchemaOrg === "Review";
    const ProductType = post.frontmatter.producttype;
    const ReviewProduct = post.frontmatter.product;
    const ReviewRating = post.frontmatter.rating;
    return React.createElement(BlogLayout, {
      location: this.props.location,
      title: siteTitle
    }, React.createElement(SEO, {
      title: post.frontmatter.title,
      description: post.frontmatter.description,
      image: OgImage,
      url: OgUrl
    }), React.createElement(Container, null, React.createElement(Row, null, React.createElement(Col, {
      sm: "8",
      className: "content"
    }, React.createElement(PostWrapper, {
      itemScope: true,
      itemType: "https://schema.org/" + SchemaOrg
    }, React.createElement(BreadCrumbs, null, React.createElement(Link, {
      to: "/"
    }, "Home"), " ", `>`, " ", React.createElement(Link, {
      to: "/blog/"
    }, "Blog"), " ", "> ", post.frontmatter.title), React.createElement(ShortLine), React.createElement(EntryHeader, null, React.createElement(EntryTitle, {
      itemProp: "headline"
    }, post.frontmatter.title), React.createElement(EntryMeta, null, React.createElement(BlogAvatar), IsReview ? React.createElement("div", null, React.createElement("span", {
      itemProp: "itemReviewed",
      itemScope: true,
      itemType: "http://schema.org/Product"
    }, React.createElement("span", {
      itemProp: "name",
      style: {
        display: `none`
      }
    }, ReviewProduct)), React.createElement("span", {
      itemProp: "reviewRating",
      itemScope: true,
      itemType: "http://schema.org/Rating"
    }, React.createElement("meta", {
      itemProp: "ratingValue",
      content: ReviewRating
    }))) : null, React.createElement("span", {
      itemProp: "author",
      itemScope: true,
      itemType: "http://schema.org/Person"
    }, React.createElement("span", {
      itemProp: "name"
    }, React.createElement("b", null, "Word Wizard"))))), React.createElement(TagBlock, null, React.createElement(PostTags, null, post.frontmatter.tags.map(tag => React.createElement(BadgeLi, {
      key: tag
    }, React.createElement(Link, {
      to: `/category/${slugify(tag)}/`
    }, React.createElement(Badge, {
      className: getColor(tag),
      style: {
        fontSize: `50%`
      },
      pill: true
    }, tag)))))), React.createElement(EntryContent, null, this.props.children, React.createElement(ShortLine), React.createElement("div", {
      className: "text-center"
    }, React.createElement("a", {
      href: "https://www.facebook.com/sparkfiles",
      rel: "noopener noreferrer",
      target: "_blank"
    }, React.createElement(DiscussButton, null, "Join The Discussion")))), React.createElement(EntryShare, null, React.createElement("div", {
      className: "socialicon"
    }, React.createElement("ul", {
      className: "list-inline m-0"
    }, React.createElement("li", {
      className: "list-inline-item font-weight-bold"
    }, " Share knowledge:"), React.createElement("li", {
      className: "list-inline-item",
      style: {
        verticalAlign: `middle`
      }
    }, React.createElement("a", {
      href: "https://facebook.com/sharer.php?u=" + OgUrl,
      rel: "noopener noreferrer",
      target: "_blank",
      "aria-label": "Facebook"
    }, React.createElement(FontAwesomeIcon, {
      style: {
        marginRight: 10,
        fontSize: `1.4em`
      },
      icon: faFacebook
    }))), React.createElement("li", {
      className: "list-inline-item",
      style: {
        verticalAlign: `middle`
      }
    }, React.createElement("a", {
      href: "https://www.reddit.com/submit?url=" + OgUrl + "&title=" + post.frontmatter.title,
      rel: "noopener noreferrer",
      target: "_blank",
      "aria-label": "Reddit"
    }, React.createElement(FontAwesomeIcon, {
      style: {
        marginRight: 10,
        fontSize: `1.4em`
      },
      icon: faReddit
    }))), React.createElement("li", {
      className: "list-inline-item",
      style: {
        verticalAlign: `middle`
      }
    }, React.createElement("a", {
      href: "https://twitter.com/intent/tweet?text=" + post.frontmatter.title + " @SparkFiles" + "&url=" + OgUrl,
      rel: "noopener noreferrer",
      target: "_blank",
      "aria-label": "Twitter"
    }, React.createElement(FontAwesomeIcon, {
      style: {
        fontSize: `1.4em`
      },
      icon: faTwitter
    })))))))), React.createElement(Col, {
      sm: "4"
    }, React.createElement(Sidebar)))));
  }
}
BlogPostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
const PostWrapper = (styled.div)`
  max-width: 720px;
  margin: auto;
`;
const ShortLine = (styled.hr)`
  width: 75%;
`;
const HeroImage = (styled.div)`
  max-height: 600px;
  margin-bottom: 10px;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  img {
    border-radius: 8px;
    border: 3px solid #f8c900;
  }
`;
const EntryTitle = (styled.h1)`
  color: #fff;
  font-size: 2.5em;
  line-height: 1.25;
`;
const EntryHeader = (styled.header)``;
const EntryMeta = (styled.div)`
  font-family: ${fonts.mainFont};
  font-size: 0.8em;
  color: #fff;
  margin-bottom: 5px;
  text-transform: uppercase;
`;
const EntryContent = (styled.div)`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #fff;
  padding: 25px 30px 30px 30px;
  box-shadow: 0 8px 40px 0 rgba(7, 7, 7, 0.2), 0 12px 40px 0 rgba(7, 7, 7, 0.2);
  .gatsby-resp-image-image {
    border-radius: 8px;
  }
  @media (max-width: 520px) {
    padding: 20px;
  }
`;
const EntryShare = (styled.div)`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 0px 40px 0 rgba(7, 7, 7, 0.2), 0 0px 40px 0 rgba(7, 7, 7, 0.2);
  background: ${colors.meatExtrachar};
  color: #fff;
  padding: 10px;
  text-align: center;
`;
const TagBlock = (styled.div)``;
const PostTags = (styled.ul)`
  padding: 0;
  display: inline-flex;
  list-style: none;
`;
const BadgeLi = (styled.li)`
  &:nth-child(1) {
    margin-right: 5px;
  }
`;
const DiscussButton = (styled.button)`
  background: ${colors.meatFire};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  border: none !important;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: 0.5s;
  margin: auto;
  &:hover {
    box-shadow: none;
  }
`;
const BreadCrumbs = (styled.small)`
  color: #fff;
`;
export const pageQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: {slug: {eq: $slug}}) {
      id
      fields {
        slug
      }
      frontmatter {
        title
        tags
        description
        date(formatString: "MMMM DD, YYYY")
        schema
        product
        producttype
        rating
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 720
              quality: 50
              layout: CONSTRAINED
              placeholder: TRACED_SVG
              tracedSVGOptions: { background: "#290849", color: "#ffd524" }
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
